.PublicTradesOneInstrument {
    /* width:100%;
    height: 100%; */

    display: grid;
    grid-template-rows: 40px auto;
    grid-template-areas: "header"
                         "grid";

}

.PublicTradesOneInstrument .ga-header {
    grid-area: header;
}

.PublicTradesOneInstrument .ga-grid {
    grid-area: grid;
    width: 100%;
}

