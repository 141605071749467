#LoginPage {
	display: grid;
	position: fixed;
	background-color: #001f3f; /*#081B2D*/

	grid-template-columns: 1fr auto 1fr;
	grid-template-rows: 80px 150px 50px auto 50px 500px;

	grid-template-areas:
		"left upper     right"
		"left logo     right"
		"left hostname right"
		"left login    right"
		"left footer   right"
		"left space   right";

	width: 100vw;
	height: 100%;

	.upper {
		grid-area: upper;
	}

	.logo {
		grid-area: logo;
		display: flex;
		justify-content: center;
		align-items: center;

		h1 {
			font-size: 5em;
		}
	}

	.hostname {
		grid-area: hostname;
		display: flex;
		justify-content: center;
		align-items: center;

		h3 {
			font-size: 0.8em;
		}
	}

	#Login {
		grid-area: login;
		padding-left: 30px;
		padding-right: 30px;
	}

	.left {
		grid-area: left;
	}

	.right {
		grid-area: right;
	}

	.footer {
		padding-top: 30px;
		grid-area: footer;
		background-color: #001f3f; /*#081B2D*/
	}

	small#client_status,
	small#backend_status {
		/* center content */
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
