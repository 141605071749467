/* 768px and up */
.FirmPage {
	display: grid;
	gap: 10px;
	grid-template-rows: 30px auto;
	grid-template-columns: auto 1600px auto;
	grid-template-areas:
		". header ."
		". users .";

	.header {
		grid-area: header;
	}

	.firms {
		grid-area: users;
	}
}
