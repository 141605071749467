/* ---------------------------------------- GRID CSS LAYOUT -------------------------------------- */

.HomeNew {
	width: 1600px; /* Fixed width of our page */
	margin: auto;
	display: grid;
	gap: 20px;
	font-size: 100%;
	background-color: #f5f5f5;

	grid-auto-rows: auto;
	grid-template-columns: 2fr 1fr 1fr;
	grid-template-areas:
		"top top top"
		"orderinput orderinput orderinput"
		"selectindex indexprice accountstatus"
		"marketgrid marketgrid marketgrid"
		"ownorders ownorders ownorders"
		"owntrades owntrades owntrades"
		"ownpositions ownpositions ownpositions"
		"trades trades trades"
		"graph graph graph"
		"marketdepth marketdepth marketdepth"
		"footer footer footer";

	.box {
		background-color: white;
		border-radius: 5px;
		padding: 5px;
	}

	.top {
		grid-area: top;
	}

	.orderinput {
		grid-area: orderinput;
	}

	.selectindex {
		grid-area: selectindex;
	}

	.accountstatus {
		grid-area: accountstatus;
	}

	.indexprice {
		grid-area: indexprice;
	}

	.marketgrid {
		grid-area: marketgrid;
	}

	.trades {
		grid-area: trades;
	}

	.owntrades {
		grid-area: owntrades;
	}

	.ownpositions {
		grid-area: ownpositions;
	}

	.ownorders {
		grid-area: ownorders;
	}

	.marketdepth {
		grid-area: marketdepth;
	}

	.graph {
		grid-area: graph;
	}

	.messages {
		grid-area: messages;
	}

	.orderhistory {
		grid-area: orderhistory;
	}

	.footer {
		height: 100px;
		grid-area: footer;
	}

	/* ---------------------------------------- END OF LAYOUT COMPONENTS -------------------------------------- */

	.wrapper {
		background-color: #fff;
		color: #444;
	}

	img {
		/* max-width: 100%; */
		max-height: 100%;
	}
}
