.OwnTrades {
   /* width:100%;
    height: 100%; */

    display: grid;
    grid-template-rows: 40px auto;
    grid-template-areas: "header"
                         "grid";

}

.OwnTrades .ga-header {
    grid-area: header;
}

.OwnTrades .ga-buttons {
    grid-area: buttons;
}

.OwnTrades .ga-grid {
    grid-area: grid;
    width: 100%;
    
}

.OwnTrades button {
    margin-right: 3px;
}
