.PublicTradesTicker {
    /* width:100%;
    height: 100%; */

    display: grid;
    grid-template-rows: auto;
    grid-template-areas: "grid";

    max-height: 100%;
    max-width: 100%;
    overflow-y: scroll;

    .ga-grid {
        grid-area: grid;
        
    }
    
                        
}

