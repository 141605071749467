/* style overrides */

.ag-header-cell-label {
    justify-content: center;
 }

.ag-cell {
    padding: 0 !important;
    padding-left: 2px !important;
}

/* div that has two rows,  one for the button bar and one for the grid */
.MarketGrid {
    /* width:  100%;
    height: 100%; */

    display: grid;
    grid-template-rows: 40px auto;
    grid-template-areas: "header"
                         "grid";
}

.MarketGrid .ga-header  {
    grid-area: header;
}

.MarketGrid .ga-buttons {

    height: 0;
    display: none; /* FOR NOW! */
    grid-area: buttons;
}

.MarketGrid .ga-grid {
    grid-area: grid;
    /* min-height:4em;
    max-height:100%;
    width:100%; */
}

.MarketGrid button {
    margin-right: 3px;
}

