$foreground-opacity: 1 !default;
$secondary-foreground-color-opacity: 1 !default;
$disabled-foreground-color-opacity: 0.5 !default;

// Colors
$border-color: transparent !default;
$background-color: #fff !default;
$foreground-color: #000 !default;
$header-background-color: $background-color !default;
$header-foreground-color: $foreground-color !default;
$icon-color: lightgray !default;
$alt-icon-color: $background-color !default;
$accent-color: transparent !default;
$range-selection-background-color: $icon-color !default;

// Sizing
$grid-size: 4px !default;
$icon-size: 12px !default;
$header-height: $grid-size * 6 + 1 !default;
$row-height: ($grid-size * 6 + 1) !default;
$rich-select-item-height: $row-height !default;
$cell-horizontal-padding: $grid-size * 3 !default;
$virtual-item-height: $grid-size * 5 !default;
$header-icon-size: 14px !default;

// Fonts
$font-family: 'Helvetica Neue', sans-serif !default;
$font-size: 14px !default;
$font-weight: 400 !default;

$secondary-font-family: $font-family !default;
$secondary-font-size: 14px !default;
$secondary-font-weight: 400 !default;

// Tooltip
$tooltip-background-color: $background-color !default;
$tooltip-foreground-color: $foreground-color !default;
$tooltip-border-radius: 2px !default;
$tooltip-padding: 5px !default;
$tooltip-border-width: 1px !default;
$tooltip-border-style: solid !default;
$tooltip-border-color: #ebebeb !default;

// Misc
$card-shadow: none !default;
$card-radius: 0 !default;
$row-border-width: 0 !default;
$transition-speed: 120ms !default;
$cell-horizontal-border: 1px solid transparent !default;

$toolpanel-indent-size: $grid-size + $icon-size !default;
$row-group-indent-size: $grid-size * 3 + $icon-size !default;

$header-cell-moving-background-color: rgb(190, 190, 190) !default;
$input-disabled-background-color: #ebebeb !default;
$input-border-color: #000000 !default;
$scroll-spacer-border: 1px solid if($border-color, $border-color, lightgrey) !default;

$dialog-background-color: $background-color !default;
$dialog-border-size: 1px !default;
$dialog-border-style: solid !default;
$dialog-border-color: $border-color !default;

$dialog-title-background-color: $header-background-color !default;
$dialog-title-foreground-color: $header-foreground-color !default;
$dialog-title-height: $header-height !default;
$dialog-title-font-family: $secondary-font-family !default;
$dialog-title-font-size: $secondary-font-size !default;
$dialog-title-font-weight: $secondary-font-weight !default;
$dialog-title-padding: 5px 10px !default;
$dialog-title-icon-size: max(20px, $icon-size) !default;

$tool-panel-background-color: $background-color !default;
$group-component-background-color: lighten($tool-panel-background-color, 1%) !default;
$group-component-border-color: lighten($border-color, 5%) !default;
$group-component-title-background-color: darken($group-component-background-color, 5%) !default;

$range-selection-chart-background-color: adjust-hue($range-selection-background-color, 25%) !default;
$range-selection-chart-category-background-color: adjust-hue($range-selection-background-color, -80%) !default;

// Icons
@import "./font-vars";

$icons_font_codes: (
    aggregation: $ag-icon-aggregation,
    arrows: $ag-icon-arrows,
    asc: $ag-icon-asc,
    cancel: $ag-icon-cancel,
    chart: $ag-icon-chart,
    checkbox-checked: $ag-icon-checkbox-checked,
    checkbox-indeterminate: $ag-icon-checkbox-indeterminate,
    checkbox-unchecked: $ag-icon-checkbox-unchecked,
    color-picker: $ag-icon-color-picker,
    column: $ag-icon-column,
    columns: $ag-icon-columns,
    contracted: $ag-icon-contracted,
    copy: $ag-icon-copy,
    cross: $ag-icon-cross,
    cut: $ag-icon-cut,
    data: $ag-icon-data,
    desc: $ag-icon-desc,
    expanded: $ag-icon-expanded,
    eye-slash: $ag-icon-eye-slash,
    eye: $ag-icon-eye,
    filter: $ag-icon-filter,
    first: $ag-icon-first,
    grip: $ag-icon-grip,
    group: $ag-icon-group,
    indeterminate: $ag-icon-indeterminate,
    last: $ag-icon-last,
    left: $ag-icon-left,
    linked: $ag-icon-linked,
    loading: $ag-icon-loading,
    maximize: $ag-icon-maximize,
    menu: $ag-icon-menu,
    minimize: $ag-icon-minimize,
    minus: $ag-icon-minus,
    next: $ag-icon-next,
    none: $ag-icon-none,
    not-allowed: $ag-icon-not-allowed,
    paste: $ag-icon-paste,
    pin: $ag-icon-pin,
    pivot: $ag-icon-pivot,
    plus: $ag-icon-plus,
    previous: $ag-icon-previous,
    radio-button-off:$ag-icon-radio-button-off,
    radio-button-on:$ag-icon-radio-button-on,
    right: $ag-icon-right,
    save: $ag-icon-save,
    small-down: $ag-icon-small-down,
    small-left: $ag-icon-small-left,
    small-right: $ag-icon-small-right,
    small-up: $ag-icon-small-up,
    tick: $ag-icon-tick,
    tree-closed: $ag-icon-tree-closed,
    tree-indeterminate: $ag-icon-tree-indeterminate,
    tree-open: $ag-icon-tree-open,
    unlinked: $ag-icon-unlinked,
);
