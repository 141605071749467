.User {
	/* width:100%;
    height: 100%; */

	display: grid;
	grid-template-rows: auto;
	grid-template-areas:
		"header"
		"grid";

	.ga-header {
		grid-area: header;
	}

	.ga-grid {
		grid-area: grid;
	}
}
