/* ---------------------------------------- GRID CSS LAYOUT -------------------------------------- */


.ProductPage {
    width: 1600px;
    margin: auto;
    display: grid;
    gap: 20px;
    padding-top: 5px;
    grid-template-rows: 130px auto;
    grid-template-columns: 1fr;
    grid-template-areas:
        "marketline"
        "page"
}

.ProductPage .marketline {
    
    grid-area: marketline;
}

.ProductPage .page {
    grid-area: page;
}
