
/* 768px and up */
@media only screen and (min-width: 768px) {
    .PositionPage {
        height: 100%;
        display: grid;
        gap: 10px;
        grid-template-rows: 30px minmax(0, 500px) 30px;
        grid-template-columns: auto 1600px auto;
        grid-template-areas:
            ". header ."
            ". positiongrid ."
    }
}

.PositionPage .header {
    grid-area: header;
}

.PositionPage .positiongrid {
    grid-area: positiongrid;
    padding: 5px;
    font-size: 100%;
}

