[type="checkbox"] {
	vertical-align: middle;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.App {
	height: 100%;
	font-size: 1em;
}

body {
	font-family: "Ubuntu", Calibri, "Trebuchet MS", sans-serif;
}

#App {
	height: 100%;
	background-color: #f5f5f5;
}

#App .topbar {
	background-color: #343a40;
	color: lightgray;
	border-bottom: 1px solid gray;
	padding-left: 10px;
	padding-right: 10px;
	height: 25px;
}

#App .topbar span {
	float: right;
}

.smallform {
	font-size: small;
}
