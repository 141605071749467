.FormRiskEvents {
    /* width:100%;
    height: 100%; */

    display: grid;
    grid-template-rows: auto;
    grid-template-areas: "header"
                         "grid";
   
}

.FirmRiskEvents .ga-header {
    grid-area: header;
}

.FirmRiskEvents .ga-grid {
    grid-area: grid;
}
