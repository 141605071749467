.OrderLine {
    font-size: 1.5em;
    font-weight: 700;

    .priceInput {
        width: 150px;
    }
    
    .qtyInput {
        width: 110px;
        margin-right: 35px;
    }

    .instrument {
        margin-right: 10px;
        font-weight: 700;
    }
    
    .hidden {
        display: none;
    }
    
    .buysell {
    
        /* center content */
        display: flex;
        align-items: center;
        justify-content: right;
            
        button {
            margin: 1%;
            padding: 0;
            color: white;
            font-weight: 700;
            font-size: 0.8rem;
            text-transform: uppercase;
            border-radius: 0;
            padding: 6px 12px;
        }
    
        .overviewBtn {
            background-color: green;
            width: 120px;
        }
    
        .buybutton {
            background-color: blue;
            width: 120px;
        }
    
        .sellbutton {
            background-color: red;
            width: 120px;
        }
    
    }
   
}


