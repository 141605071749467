.OwnOrders {
    /* width:100%;
    height: 100%; */

    display: grid;
    grid-template-rows: auto;
    grid-template-areas: "header"
                         "grid";
   
}

.OwnOrders .ga-header {
    grid-area: header;
}

.OwnOrders .ga-buttons {
    grid-area: buttons;
}

.OwnOrders .ga-order {
    
}

.OwnOrders .ga-grid {
    grid-area: grid;
}

.OwnOrders button {
        margin: 1%;
        padding: 0;
        color: white;
        font-weight: 700;
        font-size: 0.8rem;
        text-transform: uppercase;
        border-radius: 0;
        padding: 6px 12px;
}

.OwnOrders .modifyButton {
    background-color: blue;
    width: 120px;
}

.OwnOrders .cancelButton {
    background-color: red;
    width: 120px;
}
