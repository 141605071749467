.TopBar {
    color: darkgray;
    display: grid;
    grid-template-columns: 500px auto 200px 300px;
    grid-template-areas: "state message firm_user time";
}

.time {
    grid-area: time;
    text-align: right;
    margin-right: 20px;
}

.message {
    grid-area: message;
}

.state {
    grid-area: state;
}

.firm_user {
    grid-area: firm_user;
}
