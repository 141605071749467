.blink-dn {
    animation: redtotransparent 1s ease;
}

.blink-up {
    animation: bluetotransparent 1s ease;
}

@keyframes redtotransparent {
    from {
        background-color: red;
        color: white;
    }

    to {
        background-color: transparent;
        color: black;
    }
}

@keyframes bluetotransparent {
    from {
        background-color: blue;
        color: white;
    }

    to {
        background-color: transparent;
        color: black;
    }
}