.ProductInfo {
    width:100%;
    height: 100%;
    padding: 10px;
    font-size: 0.8em;
    color: black;

    .infolabel {
        font-weight: 700;
        padding-right: 10px;
    }
}
