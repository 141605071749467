/* ---------------------------------------- GRID CSS LAYOUT -------------------------------------- */

.ProductPage2 {
    display: grid;
    grid-gap: 20px;
    grid-template-rows: 30px auto; /* 300px 300px 300px; */
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: "productinfo marketdepth marketdepth ownpositionforinstrument"
                         "productinfo marketdepth marketdepth placeorder"
                         "ownorders ownorders ownorders ownorders"
/*                         "graph graph graph graph" */
                         "lasttrades lasttrades lasttrades lasttrades ";
    padding: 5px;
}

.ProductPage2 .ownpositionforinstrument {
    grid-area: ownpositionforinstrument;
}

.ProductPage2 .marketdepth {
    height: 300px;
    grid-area: marketdepth;
    background-color: white;
}

.ProductPage2 .placeorder {
    
    grid-area: placeorder;
    background-color: white;
}

.ProductPage2 .lasttrades {
    height: 100%;
    grid-area: lasttrades;
}

.ProductPage2 .ownorders {
    height: 100%;
    grid-area: ownorders;
}

.ProductPage2 .graph {
    grid-area: graph;
    height: 520px;
    overflow: hidden;
}

.ProductPage2 .productinfo {
    height: 300px;
    grid-area: productinfo;
    background-color: white;
}


.productPage2 .box {
    border: 1px solid gray;
}