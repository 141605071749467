/* ---------------------------------------- GRID CSS LAYOUT -------------------------------------- */

.AccountStatus {
	// height: 50px;
	padding: 0;
	margin: 0;

	.amount {
		font-size: x-large;
		margin-right: 10px;
	}

	.blue {
		font-size: large;
		color: blue;
	}

	.red {
		font-size: large;
		color: red;
	}

	.blink-dn {
		animation: redbg_to_transparentbg 1s ease;
		border-radius: 5px;
	}
	
	.blink-up {
		animation: bluebg_to_transparentbg 1s ease;
		border-radius: 5px;
	}
	
	@keyframes redbg_to_transparentbg {
		from {
			background-color: red;
			color: white;
		}
	
		to {
			background-color: transparent;
			color: black;
		}
	}
	
	@keyframes bluebg_to_transparentbg {
		from {
			background-color: blue;
			color: white;
		}
	
		to {
			background-color: transparent;
			color: black;
		}
	}
}
