.ag-chart {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    .ag-chart-components-wrapper {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        overflow: hidden;
        .ag-chart-canvas-wrapper {
            position: relative;
            flex: 1 1 auto;
            overflow: hidden;
            canvas {
                display: block;
            }
        }

        .ag-chart-menu {
            position: absolute;
            top: 10px;
            right: 20px;
            width: 24px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
    }
    &:not(.ag-has-menu):hover {
        .ag-chart-menu {
            opacity: 1;
            pointer-events: all;
        }
    }
    .ag-chart-docked-container {
        position: relative;
        width: 0;
        min-width: 0;
        transition: min-width 0.4s;
        .ag-panel {
            border-width: 0 0 0 1px;
        }
    }
}

.ag-chart-tabbed-menu {
    > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
    .ag-tab-header {
        flex: none;
        user-select: none;
        cursor: default;
    }
    .ag-tab-body {
        display: flex;
        flex: 1 1 auto;
        align-items: stretch;
        > div {
            width: 100%;
            overflow: hidden;
            overflow-y: auto;
        }
        overflow: hidden;
    }

    .ag-chart-settings {
        overflow-x: hidden;
        .ag-chart-settings-wrapper {
            position: relative;
            flex-direction: column;
            width: 100%;
            height: 100%;
            display: flex;
            overflow: hidden;
            .ag-chart-settings-nav-bar {
                width: 100%;
                display: flex;
                height: 30px;
                align-items: center;
                .ag-nav-card-selector {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex: 1 1 auto;
                    height: 100%;
                    .ag-nav-card-item {
                        opacity: 0.2;
                        &.ag-selected {
                            opacity: 1;
                        }
                    }
                }
                .ag-chart-settings-prev-btn, .ag-chart-settings-next-btn {
                    position: relative;
                    flex: none;
                    button {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        opacity: 0;
                    }
                }
            }
            .ag-chart-settings-mini-charts-container {
                position: relative;
                flex: 1 1 auto;
                overflow-x: hidden;
                overflow-y: auto;
                .ag-chart-settings-mini-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    min-height: 100%;
                    overflow: hidden;
                    .ag-group-component {
                        flex: none;
                    }
                    .ag-group-component-container {
                        flex-direction: row;
                        flex-wrap: wrap;
                        .ag-chart-mini-thumbnail {
                            flex: none;
                        }
                    }
                    &.ag-animating, &.ag-animating {
                        transition: left 0.3s;
                        transition-timing-function: ease-in-out;
                    }
                    .ag-chart-mini-thumbnail {
                        cursor: pointer;
                        canvas {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .ag-chart-data-wrapper, .ag-chart-format-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        user-select: none;
        > div {
            display: flex;
            margin: 0;
            padding-bottom: 2px;
            &:not(:first-child) {
                margin-top: 0;
            }
        }
    }

    .ag-chart-format-wrapper {
        overflow-x: hidden;
        .ag-group-component {
            .ag-group-subgroup {
                justify-content: space-between;
            }

        }
    }
}

// sizing
.ag-chart {
    .ag-chart-canvas-wrapper.ag-chart-empty {
        > canvas {
            visibility: hidden;
        }
        .ag-chart-empty-text {
            display: flex;
        }
    }

    .ag-chart-empty-text {
        display: none;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    .ag-chart-menu {
        opacity: 0;
        pointer-events: none;

        > span {
            opacity: 0.5;
            line-height: 24px;
            font-size: 24px;
            width: 24px;
            height: 24px;
            margin: 2px 0;
            cursor: pointer;
            &:hover {
                border-radius: 5px;
            }
        }
    }

    .ag-chart-docked-container {
        min-width: 0;
        width: 0;
    }
}

.ag-chart-tabbed-menu {
    .ag-tab-body {
        padding: 0;
    }

    .ag-chart-settings {
        .ag-chart-settings-nav-bar {
            padding: 0 10px;
            user-select: none;
            .ag-nav-card-selector {
                padding: 0 10px;
                .ag-nav-card-item {
                    cursor: pointer;
                }
            }
        }
    }
}
