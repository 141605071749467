.MarketLine {
	background: white;
	width: 1600px;
	height: 100%;
	margin: auto;
	display: grid;

	grid-template-rows: 60px 55px;
	grid-template-columns: 1.5fr 1.5fr 1fr;
	grid-template-areas:
		"title title title"
		"prices instrument buysell";
	color: black;
	font-weight: 700;

	/* TITLE */
	.title {
		grid-area: title;
		display: flex;
		align-items: center;

		.name {
			font-size: 1.8rem;
			text-transform: uppercase;
		}

		.symbol {
			font-size: 1rem;
			text-transform: uppercase;
			font-weight: normal;
			color: gray;
		}
	}

	/* PRICES */
	.prices {
		grid-area: prices;

		table  {
			font-size: 1.2rem;

			th {
				color: gray;
			}

			td.last {
				font-size: 1.2rem;
			}

			thead {
				font-size: small;
			}

			th,
			td {
				vertical-align: baseline;
				padding-left: 16px;
				padding-right: 16px;
			}
		}
	}

	/* INTRUMENT */
	.instrument {
		grid-area: instrument;
		/* center content */
		display: flex;
		align-items: center;
		justify-content: left;

		.SelectInstrument {
			width: 100%;
		}
	}

	/* BUYSELL */
	.buysell {
		grid-area: buysell;

		/* center content */
		display: flex;
		align-items: center;
		justify-content: right;

      
		button {
			margin: 1%;
			width: 40%;
			color: white;
			font-weight: 700;
			font-size: 0.8em;
			text-transform: uppercase;
			border-radius: 0;
			height: 60%;

		}
    }
    
    .tradebutton {
        background-color: blue;
        width: 120px;
	}
	
    .buybutton {
        background-color: blue;
        width: 120px;
    }

    .sellbutton {
        background-color: red;
        width: 120px;
    }

    .overviewbutton {
        background-color: green;
        width: 120px;
    }

}
