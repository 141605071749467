.PlaceOrder {

    .pricelabel {
        font-size: 0.7em;
    }
    
    .price {
        width: 100%;
    }
    
    .quantitylabel {
        font-size: 0.7em;
    }
    
    .quantity {
        width: 100%;
    }

    .validitylabel {
        font-size: 0.7em;
    }
    
    .validity {
        width: 100%;
    }
    

    .buysell {
    
        /* center content */
        display: flex;
        align-items: center;
        justify-content: right;
            
        button {
            margin: 1%;
            padding: 0;
            color: white;
            font-weight: 700;
            font-size: 0.8rem;
            text-transform: uppercase;
            border-radius: 0;
            padding: 6px 12px;
        }
    
        .overviewBtn {
            background-color: green;
            width: 120px;
        }
    
        .buybutton {
            background-color: blue;
            width: 120px;
        }
    
        .sellbutton {
            background-color: red;
            width: 120px;
        }
    
    }

    .center{
        width: 150px;
          margin: 40px auto;
          
        }
}


