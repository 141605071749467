.MarketPage {
    display: grid;
    
    gap: 10px;
    grid-template-rows: auto;
    grid-template-columns: auto 1600px auto;
    grid-template-areas:
        "leftcol marketgrid  rightcol"
        "leftcol marketdepth rightcol"
        "footer footer footer";

    .MarketGrid {
        grid-area: marketgrid;
        padding: 5px;
        font-size: 100%;
    }

    .MarketDepth {
        grid-area: marketdepth;
        padding: 5px;
        font-size: 100%;
    }

    .leftcol {
        grid-area: leftcol;
    }

    .rightcol {
        grid-area: rightcol;
    }

    .footer {
        height: 100px;
        grid-area: footer;
    }
}

