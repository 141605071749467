.CreateProductPage {
    display: grid;
    gap: 10px;
    grid-template-rows: 60px auto 30px;
    grid-template-columns: auto 500px auto;
    grid-template-areas:
        ". header  ."
        ". instrument .";

    .header {
        grid-area: header;
    }
        
    .instrumentform {
        grid-area: instrument;
    }

    .mycheckbox {
        margin-left:10px;
        margin-right:10px;
    }
}

