/* 768px and up */
.AccountPage {
	display: grid;
	gap: 10px;
	grid-template-rows: 30px auto;
	grid-template-columns: auto 1600px auto;
	grid-template-areas:
		". header ."
		". account ."
		". risksettings ."
		". firmriskevents ."
		". positions ."
		". orders ."
		". trades .";

	.header {
		grid-area: header;
	}

	.account {
		grid-area: account;
	}

	.risksettings {
		grid-area: risksettings;
	}

	.firmriskevents {
		grid-area: firmriskevents;
	}

	.positions {
		grid-area: positions;
	}

	.orders {
		grid-area: orders;
	}

	.trades {
		grid-area: trades;
	}
}
