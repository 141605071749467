/* ---------------------------------------- GRID CSS LAYOUT -------------------------------------- */

.ProductPage1 {
    display: grid;
    gap: 20px;
    grid-template-rows: 30px 200px 280px auto;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
        "graph ownpositionforinstrument"
        "graph marketdepth"
        "graph productinfo"
        "trades productinfo "
}

.ProductPage1 .ownpositionforinstrument {
    grid-area: ownpositionforinstrument;
}

.ProductPage1 .graph {
    grid-area: graph;
    max-height: 500px;
    min-height: 500px;
    overflow: hidden;
}

.ProductPage1 .marketdepth {
    grid-area: marketdepth;
    max-height: 200px;
    min-height: 200px;
    overflow: hidden;
}

.ProductPage1 .trades {
    grid-area: trades;
    background-color: white;
}


.ProductPage1 .productinfo {
    grid-area: productinfo;
    background-color: white;
}
