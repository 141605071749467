
.PublicTradeTickerPage {
    display: grid;
    gap: 10px;
    grid-template-rows: auto;
    grid-template-columns: auto 1600px auto;
    grid-template-areas:
        ". tradeticker .";

        .tradeticker {
            
            grid-area: tradeticker;
            padding: 5px;
            font-size: 100%;
        }
}


