
/* 768px and up */
.OwnTradesHistoryPage {
    display: grid;
    gap: 10px;
    grid-template-rows: 30px auto;
    grid-template-columns: auto 1600px auto;
    grid-template-areas:
        ". header ."
        ". trades .";

    .header {
        grid-area: header;
    }
    
    .trades {
        grid-area: trades;
        padding: 5px;
    }
}

