.PlaygroundPage {
	height: 100%;
	display: grid;
	gap: 10px;
	grid-template-rows: 30px auto;
	grid-template-columns: auto 1600px auto;
	grid-template-areas:
		". header ."
		". body .";
}

.PlaygroundPage .header {
	grid-area: header;
}

.PlaygroundPage .body {
	grid-area: body;
	padding: 5px;
	font-size: 100%;
}

/*  HACK! */

.my-legend {
	margin: 20px;
	width: 100px;
}

.my-fieldset {
	border: 3px solid;
	padding: 5px;
}

.DayPickerInput {
	display: block;
}

.demoForm {
	padding: 10px;
}

.underlyingPart {
	border-radius: 5px;
	border: 1px solid gray;
	padding: 10px;
	margin: 5px;
}

.futurePart {
	border-radius: 5px;
	border: 1px solid gray;
	padding: 10px;
	margin: 5px;
}

.buttonPart {
	border-radius: 5px;
	border: 1px solid gray;
	padding: 10px;
	margin: 5px;
}
