.MarketDepth {

    // background-color: gold;

    // width:100%;
    height: 100%;

    display: grid;
    grid-template-rows: 30px auto;
    grid-template-areas: "mdheader"
                         "mdgrid";

    .md-header {
        grid-area: mdheader;
        // background-color: darkkhaki;
        height: 30px;
    }

    .md-grid {
        grid-area: mdgrid;
        // background-color: springgreen;

    }
    
    button {
        margin-right: 3px;
    }
    
    
}

